section#skills {
  margin-top: 50px;
  padding: 20px;
  background-color: #fff;
}

.competenze {
  font-size: 1.1rem;
  font-weight: 500;
  color: #000;
  margin-bottom: 50px;
}

.title {
  font-size: 1.1rem;
  font-weight: 500;
  color: #000;
}

.desc {
  font-size: 1.1rem;
  color: #666;
  margin: 5px 0;
  margin-top: 0.6rem;
  margin-bottom: 1rem;
  line-height: 1.8rem;
}
