section#experiences {
  padding: 20px;
  background-color: #fff;
  margin-top: 3rem;
}

h2 {
  font-size: 20px;
  margin-bottom: 20px;
}

.experience {
  padding: 1rem 0;
}

.experience h3 {
  font-weight: 500;
  font-size: 1.1rem;
  margin: 0;
}

p {
  font-size: 1.1rem;
  color: #666;
  margin: 5px 0;
  margin-top: 0.6rem;
  margin-bottom: 1rem;
  line-height: 1.8rem;
}

.experience a {
  color: #000;
  text-decoration: underline;
  text-decoration-thickness: 1px;
  text-underline-offset: 5px;
  font-size: 1rem;
}

section#experiences a[href="#all-experiences"] {
  display: block;
  margin-top: 20px;
  text-align: left;
  color: #000;
  text-decoration: none;
  font-weight: 500;
}

.title {
  font-size: 1.1rem;
  font-weight: 500;
  color: #000;
}

.date {
  font-size: 0.8rem;
  color: #00000072;
  margin-bottom: 1rem;
}
