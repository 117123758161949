header {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: #fff;
}

nav {
  width: 100%;
  margin-bottom: 1.5rem;
}

nav ul {
  display: flex;
  list-style-type: none;
  padding: 0;
  margin: 20px 0 0;
  justify-content: space-between;
}

nav ul li {
  margin: 0 15px;
}

nav ul li a {
  text-decoration: none;
  color: #00000073;
  font-size: 1.2rem;
}

.active {
  color: #000;
}

/* Per dispositivi con larghezza dello schermo inferiore a 768px */
@media (max-width: 768px) {
  nav ul {
    flex-direction: column;
    align-items: center;
  }

  nav ul li {
    margin: 10px 0;
  }
}
