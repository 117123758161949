section#projects {
  padding: 20px;
  background-color: #fff;
  margin-top: 3rem;
}

h2 {
  font-size: 20px;
  margin-bottom: 20px;
}

.project {
  padding: 1rem 0;
}

.project h3 {
  font-weight: 500;
  font-size: 1.1rem;
  margin: 0;
}

p {
  font-size: 1.1rem;
  color: #666;
  margin: 5px 0;
  margin-top: 0.6rem;
  margin-bottom: 1rem;
  line-height: 1.8rem;
}

.project a {
  color: #000;
  text-decoration: underline;
  text-decoration-thickness: 1px;
  text-underline-offset: 5px;
  font-size: 1rem;
}

section#projects a[href="#all-projects"] {
  display: block;
  margin-top: 20px;
  text-align: left;
  color: #000;
  text-decoration: none;
  font-weight: 500;
}

.title {
  font-size: 1.1rem;
  font-weight: 500;
}

.date {
  font-size: 0.8rem;
  color: #00000072;
  margin-bottom: 1rem;
}

.project-image-background {
  height: "180px";
  width: "180px";
  background-size: "cover";
  background-position: "center";
  border-radius: "10px";
}

.project-image {
  margin-left: 60px;
}

/* Per dispositivi con larghezza dello schermo inferiore a 768px */
@media (max-width: 768px) {
  .project {
    flex-direction: column;
    align-items: flex-start;
  }

  .project-image {
    margin-left: 0;
  }

  .project-image {
    margin-left: 0;
    margin-top: 20px;
  }
}
