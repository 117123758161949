.profile img {
  border-radius: 50%;
  width: 100px;
  height: 100px;
}

.profile h1 {
  margin: 10px 0;
  font-size: 24px;
  margin: 10px 0;
  font-weight: 500;
}

.profile p {
  font-size: 1.1rem;
  line-height: 1.8rem;
  color: #666;
  margin-top: 1.2rem;
  margin-bottom: 1.2rem;
}

.profile a {
  color: #000;
  text-decoration: underline;
  text-decoration-thickness: 1px;
  text-underline-offset: 5px;
  font-size: 1rem;
}
