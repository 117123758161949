@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");

.App {
  padding: 5rem;
  background-color: #f5f5f5;
  border-radius: 0px;
  font-family: "Inter", sans-serif;
}

.background {
  background-color: #fff;
  padding: 3rem 15rem;
  border-radius: 20px;
}

/* Per dispositivi con larghezza dello schermo inferiore a 1200px */
@media (max-width: 1200px) {
  .background {
    padding: 3rem 10rem;
  }
}

/* Per dispositivi con larghezza dello schermo inferiore a 992px */
@media (max-width: 992px) {
  .background {
    padding: 3rem 5rem;
  }
}

/* Per dispositivi con larghezza dello schermo inferiore a 768px */
@media (max-width: 768px) {
  .background {
    padding: 3rem 2rem;
  }
}

/* Per dispositivi con larghezza dello schermo inferiore a 576px */
@media (max-width: 576px) {
  .App {
    padding: 1rem;
  }

  .background {
    padding: 3rem 1rem;
  }
}
