footer {
  padding: 20px;
  background-color: #fff;
  margin-top: 50px;
}

footer h2 {
  font-size: 20px;
}

.contact-links a {
  color: #000;
  text-decoration: underline;
  text-decoration-thickness: 1px;
  text-underline-offset: 5px;
  font-size: 1rem;
}

.feedback p {
  font-size: 14px;
  color: #666;
}

.stars {
  font-size: 16px;
  color: #000;
  text-decoration: underline;
  text-decoration-thickness: 1px;
  text-underline-offset: 5px;
  font-size: 1rem;
}

footer {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  background-color: #fff;
}

.contact-links {
  display: flex;
  flex-direction: column;
}

.feedback {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.links {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

/* Stili per schermi di larghezza inferiore a 768px */
@media (max-width: 768px) {
  footer {
    flex-direction: column;
  }

  .contact-links {
    margin-top: 20px;
  }

  .feedback {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}
